<template>
  <v-dialog
    v-model="dialogConta"
    persistent
    @click:outside="$emit('update:dialogConta', false)"
    @keydown.esc="$emit('update:dialogConta', false)"
    width="500px"
    max-width="500px"
    :scrollable="false"
  >
    <v-card flat>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.conta") }}: {{ conta.id }}
        <v-spacer></v-spacer>
        {{ conta.ordem }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('update:dialogConta', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pa-2">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <DataField
                  :data_sync.sync="conta.data_vencimento"
                  label="Data Vencimento"
                  :obrigatorio="formRules ? true : false"
                  :data_min="today"
                  :disabled="visualizar"
                />
              </v-col>

              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="conta.valor"
                  :label="$tc('global.valor')"
                  :placeholder="$tc('global.valor')"
                  :rules="formRules"
                  outlined
                  flat
                  dense
                  :disabled="conta.origem === 'MANUAL' ? false : true || visualizar"
                  hide-details
                  v-currency="vCurrencyOptions"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="!visualizar">
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="semMotivo"
          :loading="loading"
          depressed
          small
          @click="updateContaPagar()"
        >
          {{ $tc("global.atualizar") }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { format } from "date-fns";
import { fetchContaPagar } from "@/api/contas-pagar/contas_pagar.js";

export default {
  name: "DialogDetalhesContaPagar",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogConta: {
      type: Boolean,
      default: false,
    },
    conta_id: {
      type: Object,
    },
    visualizar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      today: format(new Date(), "yyyy-MM-dd"),
      valorJurosOriginal: 0,
      conta: {},
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: this.conta?.moeda === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  async mounted() {
    this.conta = await fetchContaPagar(this.conta_id);
  },
};
</script>

<style></style>
